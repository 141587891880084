<template>
  <div
    class="parameters-panel-select"
    :class="{'parameters-panel-select--collapsed': isCollapsed}"
    v-click-outside="() => this.isCollapsed = true"
  >
    <div class="parameters-panel-select__top" @click="toggleCollapse">
      <div class="parameters-panel-select__top-value">
        <template v-if="multiple">
          <template v-for="(id, index) in value">{{ valueObj[id].label }}{{ index !== value.length - 1 ? ', ' : '' }}</template>
        </template>
        <template v-else>{{ value }}</template>
      </div>
      <div class="va-icon entypo entypo-down-open parameters-panel-select__top-arrow" />
    </div>
    <ul class="parameters-panel-select__dropdown">
      <li
        class="parameters-panel-select__dropdown-item"
        :class="{'parameters-panel-select__dropdown-item--active': multiple ? valueObj[item.id].id !== undefined : item === value}"
        v-for="item in items"
        :key="multiple ? item.id : item"
        @click="onSelect(item)"
      >
        <template v-if="multiple">
          <span>{{ item.label }}</span>
        </template>
        <template v-else>{{ item }}</template>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ParametersPanelSelect",

  props: {
    value: {
      type: [String, Number, Array],
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isCollapsed: true
    }
  },

  computed: {
    valueObj() {
      if (this.multiple) {
        const valueObj = this.value.reduce((all, cur) => ({...all, [cur]: {}}), {})
        this.items.forEach(item => {
          if (valueObj[item.id]) {
            valueObj[item.id] = item
          }
        })
      }
      return undefined
    }
  },

  methods: {
    toggleCollapse () {
      this.isCollapsed = !this.isCollapsed
    },

    getItemById(id) {
      return this.items.find(item => item.id === id) || {}
    },

    onSelect (item) {
      if (this.multiple) {
        this.$emit('input', this.value.includes(item.id) ? this.value.filter(v => v !== item.id) : [...this.value, item.id])
      } else {
        this.isCollapsed = true
        this.$emit('input', item)
      }
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
